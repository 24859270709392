<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g fill="currentColor">
      <path
        d="M42.106 23.474a15.136 15.136 0 0 0-8.791-14.585 14.437 14.437 0 0 0-16.606 3.955c-4.062 4.947-4.289 12.318-.53 17.145a17.842 17.842 0 0 0 1.431 1.574 6.137 6.137 0 0 1 1.387 1.719 6.812 6.812 0 0 1-.023 2.573 16.908 16.908 0 0 0-.163 2.866 10.038 10.038 0 0 0 8.4 9.184 9.475 9.475 0 0 0 1.319.091 10.015 10.015 0 0 0 9.918-8.239l-5.4-1.009a4.4 4.4 0 0 1-8.744-.306 12.479 12.479 0 0 1 .132-1.95 11.072 11.072 0 0 0-.208-4.908 10.028 10.028 0 0 0-2.7-3.886 13.591 13.591 0 0 1-1.006-1.09c-2.146-2.756-1.949-7.367.441-10.277a9.079 9.079 0 0 1 10.177-2.4 9.611 9.611 0 0 1 5.486 9.152Z"
      />
      <path d="m5.863 16.373 1.864-5.175 6.308 2.273-1.864 5.174z" />
      <path d="m10.65 7.334 4-3.772 4.586 4.863-4 3.772z" />
      <path d="M19.271 1.233 24.63 0l1.426 6.196-5.36 1.233z" />
      <path
        d="M32.754 28.825a2.749 2.749 0 0 0 2.849-2.812v-.219c-.059-2.562-.133-5.75-2.209-8.2a7.4 7.4 0 0 0-8.881-1.911 7.956 7.956 0 0 0-4.05 6.893l5.49.3a2.675 2.675 0 0 1 1.051-2.287 1.879 1.879 0 0 1 2.2.567 4.967 4.967 0 0 1 .812 2.686 6.071 6.071 0 0 0-3.236 3.076 6.632 6.632 0 0 0 1.08 7.085 7.092 7.092 0 0 0 5.268 2.316 6.961 6.961 0 0 0 1.3-.122l-1.036-5.4a1.589 1.589 0 0 1-1.418-.437 1.159 1.159 0 0 1-.21-1.129c.181-.404.802-.413.99-.406Z"
      />
    </g>
  </svg>
</template>
